var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-pane",
    attrs: {
      "id": "tab-scraperruns",
      "role": "tabpanel"
    }
  }, [_c('div', [_c('div', {
    staticClass: "px-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('FormGroup', {
    staticClass: "mb-0 ml-4",
    attrs: {
      "id": "suppliers",
      "type": "select2",
      "placeholder": "All Suppliers",
      "label": "Filter Suppliers",
      "config": {
        allowClear: true
      },
      "options": _vm.filteredSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      })
    },
    model: {
      value: _vm.filters.selectedSupplier,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "selectedSupplier", $$v);
      },
      expression: "filters.selectedSupplier"
    }
  }), _c('FormGroup', {
    staticClass: "mb-0 ml-2",
    staticStyle: {
      "width": "14rem"
    },
    attrs: {
      "id": "graph-date-range",
      "date-format": "DD MMM YYYY",
      "type": "dateRangePicker",
      "label": "Date Range",
      "date-range": _vm.customDateRanges
    },
    on: {
      "input": _vm.loadScraperStats
    },
    model: {
      value: _vm.filters.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "dateRange", $$v);
      },
      expression: "filters.dateRange"
    }
  })], 1)])]), _c('div', {
    staticClass: "StatsTable-wrapper table-wrapper block-content"
  }, [_vm.scraperRunsLoadingAction.list ? _c('Spinner') : _c('table', {
    staticClass: "StatsTable table table-striped table-hover table-borderless table-vcenter fs-sm"
  }, [_c('thead', [_c('tr', {
    staticClass: "text-uppercase"
  }, [_c('th', {
    staticClass: "fw-bold"
  }, [_vm._v("Supplier")]), _vm._l(_vm.statuses, function (status) {
    return _c('th', {
      key: status.title,
      staticClass: "d-none d-sm-table-cell fw-bold",
      staticStyle: {
        "width": "16%"
      }
    }, [_vm._v(" " + _vm._s(status.title) + " ")]);
  }), _c('th', {
    staticClass: "d-none d-sm-table-cell fw-bold",
    staticStyle: {
      "width": "1%"
    }
  })], 2)]), _c('tbody', _vm._l(_vm.tableFilteredSuppliers, function (supplier) {
    return _c('tr', {
      key: supplier._id
    }, [_c('td', [supplier._id ? _c('router-link', {
      staticClass: "nav-link text-capitalize pl-0",
      attrs: {
        "active-class": "active",
        "to": {
          name: 'connectors-runs',
          query: {
            supplierId: supplier._id
          }
        },
        "exact": ""
      }
    }, [_vm._v(_vm._s(supplier.name))]) : _c('p', {
      staticClass: "text-capitalize m-2"
    }, [_vm._v("TOTAL")])], 1), _vm._l(_vm.statuses, function (status, index) {
      return _c('td', {
        key: index,
        staticClass: "d-none d-sm-table-cell text-end"
      }, [_c('p', {
        staticClass: "mb-0"
      }, [_c('strong', [_vm._v(_vm._s(_vm.getNumbOfRuns(status, supplier.scraperRuns)))]), _vm._v("(" + _vm._s(_vm.getPercentageOfRuns(status, supplier.scraperRuns)) + "%) ")]), _c('div', {
        staticClass: "progress mb-0",
        staticStyle: {
          "height": "10px",
          "width": "60%"
        }
      }, [_c('div', {
        staticClass: "progress-bar bg-primary",
        class: [status.colour],
        style: "width: ".concat(_vm.getPercentageOfRuns(status, supplier.scraperRuns), "%"),
        attrs: {
          "role": "progressbar",
          "aria-valuenow": _vm.getPercentageOfRuns(status, supplier.scraperRuns),
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }
      })])]);
    }), _c('td', [supplier._id ? _c('router-link', {
      staticClass: "nav-link text-capitalize",
      attrs: {
        "active-class": "active",
        "to": {
          name: 'connectors-manage',
          query: {
            supplierId: supplier._id
          }
        },
        "exact": ""
      }
    }, [_c('i', {
      staticClass: "fas fa-cog mr-2"
    })]) : _vm._e()], 1)], 2);
  }), 0)])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }