<template>
  <div id="tab-scraperruns" class="tab-pane" role="tabpanel">
    <div>
      <div class="px-3">
        <div class="row">
          <div class="col-12">
            <div class="d-flex">
              <FormGroup
                id="suppliers"
                v-model="filters.selectedSupplier"
                type="select2"
                class="mb-0 ml-4"
                placeholder="All Suppliers"
                label="Filter Suppliers"
                :config="{ allowClear: true }"
                :options="filteredSuppliers.map(s => ({ label: s.name, value: s._id }))"
              />
              <FormGroup
                id="graph-date-range"
                v-model="filters.dateRange"
                date-format="DD MMM YYYY"
                type="dateRangePicker"
                label="Date Range"
                class="mb-0 ml-2"
                style="width: 14rem"
                :date-range="customDateRanges"
                @input="loadScraperStats"
              />
            </div>
          </div>
        </div>

        <div class="StatsTable-wrapper table-wrapper block-content">
          <Spinner v-if="scraperRunsLoadingAction.list"></Spinner>
          <table v-else class="StatsTable table table-striped table-hover table-borderless table-vcenter fs-sm">
            <thead>
              <tr class="text-uppercase">
                <th class="fw-bold">Supplier</th>
                <th v-for="status in statuses" :key="status.title" class="d-none d-sm-table-cell fw-bold" style="width: 16%">
                  {{ status.title }}
                </th>
                <th class="d-none d-sm-table-cell fw-bold" style="width: 1%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="supplier in tableFilteredSuppliers" :key="supplier._id">
                <td>
                  <router-link
                    v-if="supplier._id"
                    class="nav-link text-capitalize pl-0"
                    active-class="active"
                    :to="{ name: 'connectors-runs', query: { supplierId: supplier._id } }"
                    exact
                    >{{ supplier.name }}</router-link
                  >
                  <p v-else class="text-capitalize m-2">TOTAL</p>
                </td>
                <td v-for="(status, index) in statuses" :key="index" class="d-none d-sm-table-cell text-end">
                  <p class="mb-0">
                    <strong>{{ getNumbOfRuns(status, supplier.scraperRuns) }}</strong
                    >({{ getPercentageOfRuns(status, supplier.scraperRuns) }}%)
                  </p>
                  <div class="progress mb-0" style="height: 10px; width: 60%">
                    <div
                      class="progress-bar bg-primary"
                      :class="[status.colour]"
                      role="progressbar"
                      :aria-valuenow="getPercentageOfRuns(status, supplier.scraperRuns)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="`width: ${getPercentageOfRuns(status, supplier.scraperRuns)}%`"
                    ></div>
                  </div>
                </td>
                <td>
                  <router-link
                    v-if="supplier._id"
                    class="nav-link text-capitalize"
                    active-class="active"
                    :to="{ name: 'connectors-manage', query: { supplierId: supplier._id } }"
                    exact
                  >
                    <i class="fas fa-cog mr-2"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '@/components/FormGroup';
import Spinner from '@/components/SpinnerLogo';

import moment from 'moment';

export default {
  name: 'ScraperDashboard',
  components: {
    FormGroup,
    Spinner
  },
  data() {
    return {
      filters: {
        selectedSupplier: null,
        dateRange: [moment(), moment()]
      },
      statuses: [
        {
          status: 'completed',
          colour: 'bg-success',
          title: 'Completed'
        },
        {
          status: 'error',
          colour: 'bg-danger',
          title: 'Failed'
        },
        {
          status: 'error',
          error: 'login',
          colour: 'bg-warning',
          title: 'Login Failure'
        },
        {
          status: 'error',
          error: 'data',
          colour: 'bg-warning',
          title: 'Data Error'
        },
        {
          status: 'running',
          colour: 'bg-warning',
          title: 'In Complete'
        }
      ],
      customDateRanges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last 30 Days': [moment().subtract(30, 'days'), moment()]
      }
    };
  },
  computed: {
    ...mapGetters({
      scraperRuns: 'scraperRun/scraperRuns',
      scraperRunsLoadingAction: 'scraperRun/loadingAction',
      suppliers: 'scraperRun/suppliers'
    }),
    tableFilteredSuppliers() {
      if (this.filters.selectedSupplier) {
        return this.filteredSuppliers.filter(sup => sup.id === this.filters.selectedSupplier);
      }

      const totalScrapers = this.filteredSuppliers.reduce((array, supplier) => {
        return [...array, ...supplier.scraperRuns];
      }, []);

      return [...this.filteredSuppliers, { name: 'TOTAL', scraperRuns: totalScrapers }];
    },
    filteredSuppliers() {
      return this.suppliers
        .filter(s => s.isEnabled)
        .map(s => {
          const scraperRuns = this.scraperRuns.filter(run => run.supplierId === s._id);
          return {
            ...s,
            scraperRuns
          };
        });
    }
  },
  mounted() {
    this.loadScraperStats();
  },
  methods: {
    ...mapActions({
      listScraperRuns: 'scraperRun/list'
    }),
    loadScraperStats() {
      const params = {
        supplierId: this.filteredSuppliers.map(s => s.id),
        $select: 'accountId,createdAt,account.name,supplierId,status,error',
        ['createdAt.gte']: moment(this.filters.dateRange[0]).format('YYYY/MM/DD'),
        ['createdAt.lte']: moment(this.filters.dateRange[1]).add(1, 'days').format('YYYY/MM/DD'),
        status: '$exists',
        $limit: 100000
      };

      if (this.filters.selectedSupplier) params.supplierId = this.filters.selectedSupplier;

      this.listScraperRuns({ data: { params } });
    },
    getPercentageOfRuns(status, scraperRuns) {
      if (!scraperRuns || scraperRuns.length === 0) return 0;

      const runStatus = status.status;
      let error = status.error;

      let statusFiltered;
      if (!error) {
        statusFiltered = scraperRuns.filter(s => s.status === runStatus && !s.error);
      } else {
        statusFiltered = scraperRuns.filter(s => s.status === runStatus && s.error === error);
      }

      if (!statusFiltered || statusFiltered.length === 0) return 0;

      return parseInt((100 * statusFiltered.length) / scraperRuns.length);
    },
    getNumbOfRuns(status, scraperRuns) {
      if (!scraperRuns || scraperRuns.length === 0) return 0;

      const runStatus = status.status;
      const error = status.error;

      if (!error) return scraperRuns.filter(s => s.status === runStatus && !s.error).length;
      return scraperRuns.filter(s => s.status === runStatus && s.error === error).length;
    }
  }
};
</script>
<style lang="scss">
.StatsTable {
  &-wrapper {
    position: relative;
  }
  &-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 85%);
    z-index: 999;
  }
}
</style>
